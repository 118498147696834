import {
    TOGGLE_LODDING
} from '../../constants/action.types'

const toggleLoading = () => {
    return {
        type: TOGGLE_LODDING
    }
}


export {
    toggleLoading
}