import React from 'react'

const Explore = props => {

    return (
        <div>
            <h1>Explore Page</h1>
        </div>
    )
}

export default Explore