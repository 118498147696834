import {
    combineReducers,
    createStore,
    applyMiddleware
} from 'redux'

import thunk from 'redux-thunk'

// importing reducers 
import ui from './reducers/ui'


const store = createStore(combineReducers({
    ui
}), applyMiddleware(thunk))

export default store