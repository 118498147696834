import React from 'react'

import {
    Container,
    Typography
} from '@material-ui/core'

const Projects = props => {

    return (

        <div>
            <Container>
                <Typography align="center" variant="h3">
                    Projects
                </Typography>
            </Container>
        </div>
    )
}

export default Projects