import React from 'react'
import { useHistory } from 'react-router-dom'
import AppDrawer from './Drawer';
import {
    AppBar,
    Toolbar,
    Typography,
    makeStyles,
    Button,
    IconButton,
    
} from '@material-ui/core'

import ninja from '../../images/onlyninja.png'
const useStyles = makeStyles(theme => ({
    offset: theme.mixins.toolbar,
  }))
const useStyle = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    imageContainer: {
        padding: '12px'
    },
    title: {
        flexGrow: 1
    },
    links: {
        cursor: 'pointer'
    }
}))

export default () => {
    const classes = useStyle()
    const classes2 = useStyles();
    const history = useHistory()
    const route = path => {
        history.push(path)
      }
    return (
        <div className={classes.root}>
            <AppBar position="sticky" elevation="0" color="transparent">
                <Toolbar>
                    <div className={classes.imageContainer}>
                        <img
                            src={ninja}
                            alt="ninja-dev-icon"
                            width="40px"
                            onClick={() => {route('/')}}
                            style={{cursor: 'pointer'}}
                            />
                    </div>

                    <div className={classes.title}> 
                    {/* {routes.map(el => <Button key={el.path} onClick={() => 
                        {route(el.path)}} >{el.name}</Button>)} */}
                    </div>
 
                    <AppDrawer/>

                </Toolbar>
            </AppBar>
            <div className={classes2.offset} />
        </div>
    )
}
