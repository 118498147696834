import {createMuiTheme} from '@material-ui/core/styles'
import { dark } from '@material-ui/core/styles/createPalette'
import './fonts.css'
const font = "'Montserrat', sans-serif;"; 
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#262525'
        },
        text: {
            primary: '#F6E1B8',
            secondary: '#F6E1B8'
        },
        background: {
            paper: '#40424E',
            default: '#333644'
        }
    },
    typography: {
        fontFamily: font
    }
})

export default theme