import React from 'react'
import { useHistory } from 'react-router-dom'

import {
    makeStyles,
    Paper,
    Container,
    Grid,
    Typography,
    IconButton
} from '@material-ui/core'

import Ninja from '../../images/onlyninja.png'
import {
    GitHub,
    LinkedIn,
    Instagram
} from '@material-ui/icons'

const useStyle = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(12)
    },
    paper: {
        padding: theme.spacing(12, 0)
    },
    image: {
        width: '80px',
        position: "relative",
        marginTop: '-30px'
    }
}))

const Footer = props => {

    const classes = useStyle()
    const history = useHistory()

    let link = path => {
        
    }

    return (
        <div className={classes.root}>
            <Paper elevation={0} className={classes.paper} >
                <Container>
                    <Grid container spacing={4}>

                        <Grid item xs={4}>
                            <Typography>
                                <span style={{ color: 'white' }}>@2020</span>  Ninja Developers
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>

                            <center>
                                <img src={Ninja} alt="" className={classes.image} />
                            </center>

                        </Grid>

                        <Grid item xs={4}>
                            <Grid container spacing={0} alignItems="flex-end">
                                <Grid item >
                                    <IconButton 
                                    color="inherit"
                                    onClick={() => {link('https://github.com/orgs/Ninja-Developers')}}
                                    >
                                        <GitHub />
                                    </IconButton>
                                </Grid>

                                <Grid item >
                                    <IconButton color="inherit">
                                        <LinkedIn />
                                    </IconButton>
                                </Grid>

                                <Grid item >
                                    <IconButton color="inherit">
                                        <Instagram />
                                    </IconButton>
                                </Grid>
                            </Grid>

                        </Grid>

                    </Grid>
                </Container>
            </Paper>
        </div>
    )
}


export default Footer