import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
    makeStyles,
    Container,
    Grid,
    Typography
} from '@material-ui/core'

// importing images 
import NinjaLogo from '../images/welcome_cats.png'
import BrandLogo from '../images/brands/microsoft_dark.svg'

// importing custom components
import Invite from '../components/invite'
import Footer from '../components/footer'

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(12),
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(22)
        }
    },
    letterspacing: {
        'letter-spacing': '5px',
        fontSize: theme.spacing(4)
    },
    boxText: {
        marginTop: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(2)
    },
    mt: {
        marginTop: theme.spacing(16),
        [theme.breakpoints.up('lg')]: {
            marginTop: theme.spacing(34)
        }
    }
}))

const Index = props => {
    const classes = useStyles()
    const history = useHistory()
    const route = path => {
        history.push(path)
    }

    return (
        <div className={classes.root}>
            <Container>

                <Grid container spacing={2}>
                    <Grid item xs={12}>

                        <center>
                            <img src={NinjaLogo} alt="" width="70%" />
                        </center>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.letterspacing} align="center">
                            Open Source initiative of Techphantoms.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography align="center" variant="h4" style={{color: 'yellow'}}>
                            Ninja Developers <span style={{color: 'red'}}>❤</span> Open Source
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <center className={classes.mt}>
                            <Invite />
                        </center>
                    </Grid>
                </Grid>

            </Container>
            <Footer />
        </div>
    )
}

export default Index