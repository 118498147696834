import {
    TOGGLE_LODDING
} from '../../constants/action.types'


const initialState = {
    isLoading: false
}

export default (state = initialState, action) => {
    switch(action.type){

        case TOGGLE_LODDING:
            return {
                ...state,
                isLoading: !state.isLoading
            }
        default:
            return state
    }
}