import React from 'react'

import {
    makeStyles,
    Container,
    Typography
} from '@material-ui/core'

const Hackathon = props => {


    return (
        <div>
            <br/><br/>
            <Container>
                <Typography>
                    We love participating in hackathons. <br/>
                    Here are some of the hackathons that we our members have attended. 
                </Typography>
            </Container>
        </div>
    )
}

export default Hackathon