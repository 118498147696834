import React, { useState } from 'react'
import Axios from 'axios'
import {
    connect
} from 'react-redux'
import {
    INVITE_URL
} from '../../constants/urls'

// import actions 
import {
    toggleLoading
} from '../../redux/actions/ui'

import {
    FormControl,
    Button,
    TextField,
    Grid,
    Paper,
    makeStyles,
    Typography
} from '@material-ui/core'

// importing custom componets 
import Success from './success'
import Failure from './failure'

const useStyle = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(4, 2)
    },
    mt: {
        marginTop: theme.spacing(3)
    }
}))

const Invite = props => {

    const classes = useStyle()

    const [email, setEmail] = useState('')
    const [mailsent, setmailsent] = useState(0)

    const sendInvite = () => {
        if (email.slice() === '') return
        props.toggleLoading()
        Axios({
            method: 'POST',
            url: INVITE_URL,
            data: {
                email: email
            }
        }).then(res => {
            setEmail('')
            props.toggleLoading()
            setmailsent(1)
        }).catch(err => {
            console.log(err)
            props.toggleLoading()
            alert('Something went wrong')

        })
    }

    return (
        <div>
            <Paper className={classes.paper} variant="outlined">
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography variant="h4">

                            We have a slack channel 😊, wanna join ?
                        </Typography>
                        <Typography style={{ marginTop: '12px' }}>
                            Enter your email and you will recieve your invitation.🎉
                        </Typography>

                    </Grid>
                    <Grid item xs={0} sm={3} />

                    {(mailsent === 0) ? <>
                        <Grid item xs={12} sm={6} className={classes.mt}>
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    value={email}
                                    onChange={e => { setEmail(e.target.value) }}
                                />

                            </FormControl>
                        </Grid>


                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => { sendInvite() }}
                            >
                                Join us
                    </Button>
                        </Grid>
                    </> : <Success />}

                </Grid>
            </Paper>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        isLoading: state.ui.isLoading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        toggleLoading: () => { dispatch(toggleLoading()) }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Invite)