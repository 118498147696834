import React from 'react'

import {
    Typography
} from '@material-ui/core'

export default props => {

    return (
        <div>

            <Typography>
                Email sent check your mail box
            </Typography>

        </div>
    )
}

