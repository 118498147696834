import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom'
import {connect} from 'react-redux'

// importing pages
import Index from './pages/index'
import Api from './pages/api'
import Hackathon from './pages/hackathons'
import Projects from './pages/projects'
import Explore from './pages/explore'


// importing components 
import Appbar from './components/appbar/index'

import {
  makeStyles,
  Backdrop,
  CircularProgress,
} from '@material-ui/core'

const useStyle = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}))

function App(props) {
  const classes = useStyle()
  return (
    <div>
      <Backdrop
        className={classes.backdrop}
        open={props.isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Router>
        <Appbar />
        <Switch>
          <Route exact path="/" component={Index} />
          <Route exact path="/api" component={Api} />
          <Route exact path="/hackathons" component={Hackathon} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/explore" component={Explore} />
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    isLoading: state.ui.isLoading
  }
}

export default connect(mapStateToProps)(App);
